  
  #right-container{
    position:relative;
    float:left;
    height: auto;
    border: 2px solid black;
    border-radius: 15px;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex:4;
  }

  #upperLayer{
    position:relative;
    flex: 0;
  margin-bottom: 1%;
  width:100%;

  }

  #allText{
    flex: 5;
    position:relative;
    width:100%;
  }

  .imagesOfProject {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position:relative;
    bottom:0;
    float:left;
    padding:2%;
    width:auto;


  }

  .imagesOfProject img{
      height: 100%;
      width: 100%;
      flex:1;
      padding:1%;

  }
  .imagesOfProject a{
    max-width:25%;
  }
  
  .displayBlockObject{
      display:none;
  }


  .tags-Text{
    text-align: center;
  }

  .title-Text{
    text-align: center;

  }

  .preamble-Text{
      padding:1%;
  }

  .body-Text{
      padding:1%;
  }
  .links-Text {
      bottom:0;
      flex: 1;
      position:relative;
  }
  .links-Text > *{
      margin:0;
  }



  #container ul{
      height:auto;
  }
  
  #container li{
      font-size: 100%;
      padding-bottom: 5px;
  }
  
  #container li:hover{
      color: lightblue;text-decoration: underline;
  }  




/**LEFT CONTAINER**/

#left-container{
    position:relative;
    float:left;
    border: 2px solid black;
    border-radius: 15px;
    height:100%;
    overflow-x: hidden;
    flex:1;
  }

#filterOutput{
    overflow-x:hidden;
    width:100%;
    height:25%;
    border: 2px solid black;
    border-radius: 15px;
    display:inline-block;
  }

  #filterOutput > div{
    float:left;  
    padding-left:5px;
    

  }
