#text {
	padding: 20px;
	margin-top: 20px;
	font-family: "Times New Roman", Times, serif;
	position: relative;
	min-height: 50vh;
	font-size: 2vh;
	width:100%;
}

#text > *{
	margin-left: 10%;
	margin-right: 10%;
}

#innehall {
	background-color: white;
	height: 100vh;
	width: 100%;
}


#Combined {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	font-family: 'Alfa Slab One', cursive;
	font-family: 'Archivo Black', sans-serif;
	overflow: hidden;
}


#box1>* {
	position: relative;
	/* background-image: url("349858.jpg");
	background-repeat: no-repeat;
	background-size: cover; */
	width: auto;
	/*container-width*/
	overflow: hidden;
	/*hide bounds of image */
	margin: 0;
	color: black;
	/*reset margin of figure tag*/
}

#Combined h1 {
	margin: 0;
	font-weight: bolder;
	text-transform: uppercase;
	display: inline;
}


#Combined p {
	margin: 0;
	font-weight: bolder;
	text-transform: uppercase;
	display: inline;
}

#box1 {
	position: absolute;
	font-size: 100%;
	width: 100%;
	height: 100%;
	margin: 0;
}

#box1>div {
	position: absolute;

}
#container{
    position:relative;
    display:flex;
    width: stretch;
    height: calc(100% - 164px);
    flex:1;
  }

body,
html {
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: Poppins, sans-serif;
	font-size: 1.2rem;
}

button {
	width: 50%;
	display: inline-block;
}
