@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width:100%;
    height:100%;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

.backgroundPicture {
	overflow: hidden;
	height: 50vh !important;
	position: relative;
	-webkit-filter: blur(0px) !important;
	        filter: blur(0px) !important;
}

.backgroundPicture img {
	position: absolute;
	object-fit: fill;
	width: 100%;
	height: 100%;
	z-index: -1;
	-webkit-filter: blur(8px);
	        filter: blur(8px);
}
.backgroundPicture video {
	position: absolute;
	object-fit: fill;
	width: 100%;
	height: 100%;
	/*filter:blur(8px);*/
}
.content{
	background-color: rgb(255, 255, 255); /* Fallback color */
	background-color: rgba(255, 255, 255, 0.0); /* Black w/opacity/see-through */
	position: absolute;
	display: flex;
	color: white;
	justify-content: center;
	flex-direction: column;
	flex-wrap: wrap;
	height: 100%;
	width: 100%;
	z-index: 0;
	top: 0;
}
.content > * {
	align-self:center;
}
#logo {
	position: relative;
	object-fit: scale-down;
	max-width: 96px;
	max-height: 96px;
	-webkit-filter: blur(0) !important;
	        filter: blur(0) !important;
	margin: auto;
}
#button1 {
	flex: 1 1;
	/* margin: auto; */
}
#textBild {
	display: inline-block;
	margin: auto;
	text-align: center;
}
#title {
	color: black;
	position: relative;
	font-size: 5vh;
	font-family: "Adamina";
	width: auto !important;
	flex: 1 1;
	margin: auto;
}

#second_title {
	position: relative;
	color: black;
	font-size: 4vh;
	font-family: "Adamina";
	width: auto !important;
	flex: 1 1;
	margin: auto;
}

#pText {
	position: relative;
	font-size: 2.8vh;
	font-family: "Adamina";
	line-height: 0.5;
	padding-top:10px;
	margin: auto;
	text-align: center;
}
#nummer {
	position: relative;
	vertical-align: bottom;
	font-family: "Adamina";
	/* font-size: 1.4vw; */
	margin: auto;
	flex: 1 1;
}

body,
div,
table {
	color: #000000;
	font: 16px "Source Sans Pro", sans-serif;
	font-weight: 400;
}

#root{
    position:relative;
    display:flex;
    flex-direction: column;
	overflow-x:hidden;
	min-height: 100vh;
}

.title {
	color: #000000;
	font: 21px "Varela Round", arial, sans-serif;
	font-weight: normal;
}

body {
	margin: 0;
}

#nav {

	width:100%;
	overflow:hidden;
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 64px;
	min-height: inherit;
	font-family: 'Poppins', sans-serif;
	background-color: black;

}

#top-menu {
	display: flex;
	justify-content: space-around;
	width: 50%;
	padding: 0;
	margin:0;

}

#textNav {
	display: flex;
	align-items: center;
	flex:0 1;
	height:100%;
}
#textNav img {
	width: 40px;
	height: 40px;
	margin: 5px;
}

#textNav p {
	display: inline;
	color: white;
	font-size: 1vw;
	justify-content:center;
	margin:0;
}

#nav li {
	list-style: none;
}

#nav li a {
	text-decoration: none;
	letter-spacing: 3px;
	font-weight: bold;
	font-size: 1vw;
	color: white;
}

#nav li a:hover:not(.active) {
	background-color: rgb(94, 94, 94);
}

/* #nav .active {
    background-color: white;
  } */
.burger {
	display: none;
	cursor: pointer;
	z-index: 100;
}
.burger div {
	width: 25px;
	height: 5px;
	margin: 5px;
	background-color: white;
	transition: all 0.3s ease;
}

footer {
	padding:10px;
	position:relative;
	bottom:0;
	background-color: black;
	height: 100px;
	width: 100%;
	clear: both;
	margin-bottom: 0;
	overflow: hidden;
}

#collection {
	color: white;
	margin-left: 15vh;
	height: 100%;
	display:flex;
	flex-direction: column;
	justify-content:center;

}

#collection p {
	font-size: 15px;
	font-family: "Source Sans Pro";
	font-weight: 400;
	margin: 0;
	display:block;
}

#collection a:link,
#collection a:visited,
#collection a:active {
	color: white;
}

#whitelogo {
	position: absolute;
	height: 100%;
	width:10vh;
	margin: 0%;
	bottom: 0;
	float: left;
}



.dropdown {
	/* display: flex; */
	overflow: hidden;
	float: right;
	margin-right: 25px;
	z-index: 100;
	margin: 5px;
}

.dropdown .dropbtn {
	font-weight: bold;
	outline: none;
	color: black;
	padding: 12px 16px;
	background-color: white;
	font-family: inherit;
	margin: 0;
	width: auto;
	border: 2px solid white;
	border-radius: 50%;
	font-size: 1.2em;
	height: 100%;
	z-index: 100;
}

.dropdown-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 555;
}

.dropdown-content a {
	float: none;
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
	text-align: left;
}

.dropdown-content a:hover {
	background-color: #ddd;
}

.dropdown:hover .dropdown-content {
	display: block;
}

#wrapper {
	height:64px;

}

@media screen and (max-width: 1200px) {
	/* #top-menu {
		width: 60% !important;
	} */
	#textNav p {
		font-size: 1em;
	}
}
@media screen and (max-height: 500px) {
	/* #top-menu {
		top: 50px !important;
	} */
}
@media screen and (max-width: 856px) {
	body {
		overflow-x: hidden;
	}
	#top-menu {
		position: absolute;
		right: 0px;
		top: 64px;
		background-color: black;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 50%;
		height: calc(100% - 164px);
		-webkit-transform: translateX(100%);
		        transform: translateX(100%);
		transition: -webkit-transform 0.5s ease-in;
		transition: transform 0.5s ease-in;
		transition: transform 0.5s ease-in, -webkit-transform 0.5s ease-in;
		justify-content: start !important;
		z-index: 50;
	}

	#top-menu li {
		opacity: 0;
		margin: 20px;
	}
	.dropdown .dropbtn {
		font-size: 1em;
	}
	.burger {
		display: block;
	}
	#textNav p {
		font-size: 1em;
	}

	#nav li a {
		font-size: 0.8em;
	}
	#container{
		flex-direction: column;
	}
}

.nav-active {
	-webkit-transform: translateX(0%) !important;
	        transform: translateX(0%) !important;
}

@-webkit-keyframes navLinkFade {
	from {
		opacity: 0;
		-webkit-transform: translateX(50px);
		        transform: translateX(50px);
	}
	to {
		opacity: 1;
		-webkit-transform: translateX(0px);
		        transform: translateX(0px);
	}
}

@keyframes navLinkFade {
	from {
		opacity: 0;
		-webkit-transform: translateX(50px);
		        transform: translateX(50px);
	}
	to {
		opacity: 1;
		-webkit-transform: translateX(0px);
		        transform: translateX(0px);
	}
}

.toggle .line1 {
	-webkit-transform: rotate(-15deg) translate(-5px, 6px);
	        transform: rotate(-15deg) translate(-5px, 6px);
}

.toggle .line2 {
	opacity: 0;
}

.toggle .line3 {
	-webkit-transform: rotate(15deg) translate(-5px, -6px);
	        transform: rotate(15deg) translate(-5px, -6px);
}

#text {
	padding: 20px;
	margin-top: 20px;
	font-family: "Times New Roman", Times, serif;
	position: relative;
	min-height: 50vh;
	font-size: 2vh;
	width:100%;
}

#text > *{
	margin-left: 10%;
	margin-right: 10%;
}

#innehall {
	background-color: white;
	height: 100vh;
	width: 100%;
}


#Combined {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	font-family: 'Alfa Slab One', cursive;
	font-family: 'Archivo Black', sans-serif;
	overflow: hidden;
}


#box1>* {
	position: relative;
	/* background-image: url("349858.jpg");
	background-repeat: no-repeat;
	background-size: cover; */
	width: auto;
	/*container-width*/
	overflow: hidden;
	/*hide bounds of image */
	margin: 0;
	color: black;
	/*reset margin of figure tag*/
}

#Combined h1 {
	margin: 0;
	font-weight: bolder;
	text-transform: uppercase;
	display: inline;
}


#Combined p {
	margin: 0;
	font-weight: bolder;
	text-transform: uppercase;
	display: inline;
}

#box1 {
	position: absolute;
	font-size: 100%;
	width: 100%;
	height: 100%;
	margin: 0;
}

#box1>div {
	position: absolute;

}
#container{
    position:relative;
    display:flex;
    width: -webkit-fill-available;
    width: stretch;
    height: calc(100% - 164px);
    flex:1 1;
  }

body,
html {
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: Poppins, sans-serif;
	font-size: 1.2rem;
}

button {
	width: 50%;
	display: inline-block;
}

html body [data-ca3_iconfont="ETmodules"]::before {
	font-family: "ETmodules";
}
[data-ca3_icon]::before {
	font-weight: normal;
	content: attr(data-ca3_icon);
}

.ca3-scroll-down-arrow {
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2hldnJvbl90aGluX2Rvd24iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiBmaWxsPSJ3aGl0ZSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTE3LjQxOCw2LjEwOWMwLjI3Mi0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwYzAuMjcsMC4yNjgsMC4yNzEsMC43MDEsMCwwLjk2OWwtNy45MDgsNy44M2MtMC4yNywwLjI2OC0wLjcwNywwLjI2OC0wLjk3OSwwbC03LjkwOC03LjgzYy0wLjI3LTAuMjY4LTAuMjctMC43MDEsMC0wLjk2OWMwLjI3MS0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwTDEwLDEzLjI1TDE3LjQxOCw2LjEwOXoiLz48L3N2Zz4=);
	background-size: contain;
	background-repeat: no-repeat;
	background-size: 100%;
}

.ca3-scroll-down-link {
	cursor: pointer;
	height: 60px;
	width: 80px;
	margin: 0px 0 0 -40px;
	line-height: 60px;
	position: absolute;
	left: 50%;
	bottom: 0px;
	color: #fff;
	text-align: center;
	font-size: 70px;
	z-index: 100;
	text-decoration: none;
	text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);

	-webkit-animation: ca3_fade_move_down 2s ease-in-out infinite;
	animation: ca3_fade_move_down 2s ease-in-out infinite;
}

/*animated scroll arrow animation*/
@-webkit-keyframes ca3_fade_move_down {
	0% {
		-webkit-transform: translate(0, -20px);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		-webkit-transform: translate(0, 20px);
		opacity: 0;
	}
}
@keyframes ca3_fade_move_down {
	0% {
		-webkit-transform: translate(0, -20px);
		        transform: translate(0, -20px);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		-webkit-transform: translate(0, 20px);
		        transform: translate(0, 20px);
		opacity: 0;
	}
}

  
  #right-container{
    position:relative;
    float:left;
    height: auto;
    border: 2px solid black;
    border-radius: 15px;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex:4 1;
  }

  #upperLayer{
    position:relative;
    flex: 0 1;
  margin-bottom: 1%;
  width:100%;

  }

  #allText{
    flex: 5 1;
    position:relative;
    width:100%;
  }

  .imagesOfProject {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position:relative;
    bottom:0;
    float:left;
    padding:2%;
    width:auto;


  }

  .imagesOfProject img{
      height: 100%;
      width: 100%;
      flex:1 1;
      padding:1%;

  }
  .imagesOfProject a{
    max-width:25%;
  }
  
  .displayBlockObject{
      display:none;
  }


  .tags-Text{
    text-align: center;
  }

  .title-Text{
    text-align: center;

  }

  .preamble-Text{
      padding:1%;
  }

  .body-Text{
      padding:1%;
  }
  .links-Text {
      bottom:0;
      flex: 1 1;
      position:relative;
  }
  .links-Text > *{
      margin:0;
  }



  #container ul{
      height:auto;
  }
  
  #container li{
      font-size: 100%;
      padding-bottom: 5px;
  }
  
  #container li:hover{
      color: lightblue;text-decoration: underline;
  }  




/**LEFT CONTAINER**/

#left-container{
    position:relative;
    float:left;
    border: 2px solid black;
    border-radius: 15px;
    height:100%;
    overflow-x: hidden;
    flex:1 1;
  }

#filterOutput{
    overflow-x:hidden;
    width:100%;
    height:25%;
    border: 2px solid black;
    border-radius: 15px;
    display:inline-block;
  }

  #filterOutput > div{
    float:left;  
    padding-left:5px;
    

  }

html {
	min-height: 100%;
}
body {
	padding: 0;
	margin: 0;
}
html,
body {
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}

#text {
	padding-left: 10%;
	padding-right: 10%;
	padding: 20px;
	margin-top: 20px;
	font-family: "Times New Roman", Times, serif;
	position: relative;
	display: inline;
}

#workPlaces {
	font-size: 60px;
	text-decoration: underline;
	width: 100%;
	font-family: 'Bebas Neue', cursive;
	letter-spacing: 3px;
	padding-left: 20px;
}

#text h2 {
	font-size: 2.5vh;
}

#corner1 {
	position: relative;
	border-radius: 25px;
	background: black;

	width: 15px;
	min-height: 100%;
	box-sizing: border-box;
	float: left;
	z-index: -1;
}
/* .circle {
	position: fixed;
	-webkit-backface-visibility: hidden;
	height: 50px;
	width: 50px;
	background-color: black;
	border-radius: 50%;
	display: block;
	bottom: 0;
	left: calc(20vw - 7.5px);
	top: 97.5%;
} */
#box {
	position: relative;
	border-style: dotted;
	width: 100%;
	z-index: 0;
	min-height: 50vh;

	/* padding: 5px; */
	/* top: 5vh; */
	background-color: black;
}

.intresserad {
	position: absolute;
	float: right;
	border: 3px;
	border-style: solid;
	border-color: black;
	bottom: 0px;
	padding-left: 5px;
	right: 0px;
	opacity: 0.3;
	padding-right: 5px;
}
.intresserad:hover {
	opacity: 1;
}
.intresserad p {
	font-size: 15px;
	font-style: oblique;
}

#box iframe {
	position: absolute;
	display: inline-block;
	width: 100%;
	height: 100%;
}
#middleCont {
	position: relative;
	display: flex;
	flex-direction: column;
	width:100%;
}
#textField {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	margin-top: 20vh;
}
#textField img {
	height: 20vh;
	width: 20vh;
	display: inline-block;
	margin-right: 100%;
}
#textField h1 {
	display: inline-block;
	font-size: 4vh;
}
#textField p {
	font-size: 2vh;
	clear: both;
}

#textField ul {
    height:auto;
}
.workexperience {
	position: relative;
	margin: 2vw;
	margin-top: 0;
	width: 50%;
	margin-bottom: 5vh;
}

.workexperience h1{
    padding-bottom:10px;
}

section {
	position: relative;
	display: flex;
	flex-direction: column;
}
#section2 {
	display: flex;
}

#BigFirstLetter::first-letter {
	float: left;
	line-height: 50%;
	width: .7em;
	font-size: 400%;
	font-family: georgia;
}

@media screen and (max-width: 768px) {
	.workexperience {
		left: 0;
	}
}
@media screen and (max-width: 500px) {
	#workPlaces {
		font-size: 8vw;
	}

	#Kombinationsbehandlingar {
		font-size: 7vw;
	}
	.intresserad {
		display: none;
	}
}
@media only screen and (min-device-width: 375px) {
}

