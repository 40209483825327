.backgroundPicture {
	overflow: hidden;
	height: 50vh !important;
	position: relative;
	filter: blur(0px) !important;
}

.backgroundPicture img {
	position: absolute;
	object-fit: fill;
	width: 100%;
	height: 100%;
	z-index: -1;
	filter: blur(8px);
}
.backgroundPicture video {
	position: absolute;
	object-fit: fill;
	width: 100%;
	height: 100%;
	/*filter:blur(8px);*/
}
.content{
	background-color: rgb(255, 255, 255); /* Fallback color */
	background-color: rgba(255, 255, 255, 0.0); /* Black w/opacity/see-through */
	position: absolute;
	display: flex;
	color: white;
	justify-content: center;
	flex-direction: column;
	flex-wrap: wrap;
	height: 100%;
	width: 100%;
	z-index: 0;
	top: 0;
}
.content > * {
	align-self:center;
}
#logo {
	position: relative;
	object-fit: scale-down;
	max-width: 96px;
	max-height: 96px;
	filter: blur(0) !important;
	margin: auto;
}
#button1 {
	flex: 1;
	/* margin: auto; */
}
#textBild {
	display: inline-block;
	margin: auto;
	text-align: center;
}
#title {
	color: black;
	position: relative;
	font-size: 5vh;
	font-family: "Adamina";
	width: auto !important;
	flex: 1;
	margin: auto;
}

#second_title {
	position: relative;
	color: black;
	font-size: 4vh;
	font-family: "Adamina";
	width: auto !important;
	flex: 1;
	margin: auto;
}

#pText {
	position: relative;
	font-size: 2.8vh;
	font-family: "Adamina";
	line-height: 0.5;
	padding-top:10px;
	margin: auto;
	text-align: center;
}
#nummer {
	position: relative;
	vertical-align: bottom;
	font-family: "Adamina";
	/* font-size: 1.4vw; */
	margin: auto;
	flex: 1;
}
