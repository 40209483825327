@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body,
div,
table {
	color: #000000;
	font: 16px "Source Sans Pro", sans-serif;
	font-weight: 400;
}

#root{
    position:relative;
    display:flex;
    flex-direction: column;
	overflow-x:hidden;
	min-height: 100vh;
}

.title {
	color: #000000;
	font: 21px "Varela Round", arial, sans-serif;
	font-weight: normal;
}

body {
	margin: 0;
}

#nav {

	width:100%;
	overflow:hidden;
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 64px;
	min-height: inherit;
	font-family: 'Poppins', sans-serif;
	background-color: black;

}

#top-menu {
	display: flex;
	justify-content: space-around;
	width: 50%;
	padding: 0;
	margin:0;

}

#textNav {
	display: flex;
	align-items: center;
	flex:0;
	height:100%;
}
#textNav img {
	width: 40px;
	height: 40px;
	margin: 5px;
}

#textNav p {
	display: inline;
	color: white;
	font-size: 1vw;
	justify-content:center;
	margin:0;
}

#nav li {
	list-style: none;
}

#nav li a {
	text-decoration: none;
	letter-spacing: 3px;
	font-weight: bold;
	font-size: 1vw;
	color: white;
}

#nav li a:hover:not(.active) {
	background-color: rgb(94, 94, 94);
}

/* #nav .active {
    background-color: white;
  } */
.burger {
	display: none;
	cursor: pointer;
	z-index: 100;
}
.burger div {
	width: 25px;
	height: 5px;
	margin: 5px;
	background-color: white;
	transition: all 0.3s ease;
}

footer {
	padding:10px;
	position:relative;
	bottom:0;
	background-color: black;
	height: 100px;
	width: 100%;
	clear: both;
	margin-bottom: 0;
	overflow: hidden;
}

#collection {
	color: white;
	margin-left: 15vh;
	height: 100%;
	display:flex;
	flex-direction: column;
	justify-content:center;

}

#collection p {
	font-size: 15px;
	font-family: "Source Sans Pro";
	font-weight: 400;
	margin: 0;
	display:block;
}

#collection a:link,
#collection a:visited,
#collection a:active {
	color: white;
}

#whitelogo {
	position: absolute;
	height: 100%;
	width:10vh;
	margin: 0%;
	bottom: 0;
	float: left;
}



.dropdown {
	/* display: flex; */
	overflow: hidden;
	float: right;
	margin-right: 25px;
	z-index: 100;
	margin: 5px;
}

.dropdown .dropbtn {
	font-weight: bold;
	outline: none;
	color: black;
	padding: 12px 16px;
	background-color: white;
	font-family: inherit;
	margin: 0;
	width: auto;
	border: 2px solid white;
	border-radius: 50%;
	font-size: 1.2em;
	height: 100%;
	z-index: 100;
}

.dropdown-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 555;
}

.dropdown-content a {
	float: none;
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
	text-align: left;
}

.dropdown-content a:hover {
	background-color: #ddd;
}

.dropdown:hover .dropdown-content {
	display: block;
}

#wrapper {
	height:64px;

}

@media screen and (max-width: 1200px) {
	/* #top-menu {
		width: 60% !important;
	} */
	#textNav p {
		font-size: 1em;
	}
}
@media screen and (max-height: 500px) {
	/* #top-menu {
		top: 50px !important;
	} */
}
@media screen and (max-width: 856px) {
	body {
		overflow-x: hidden;
	}
	#top-menu {
		position: absolute;
		right: 0px;
		top: 64px;
		background-color: black;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 50%;
		height: calc(100% - 164px);
		transform: translateX(100%);
		transition: transform 0.5s ease-in;
		justify-content: start !important;
		z-index: 50;
	}

	#top-menu li {
		opacity: 0;
		margin: 20px;
	}
	.dropdown .dropbtn {
		font-size: 1em;
	}
	.burger {
		display: block;
	}
	#textNav p {
		font-size: 1em;
	}

	#nav li a {
		font-size: 0.8em;
	}
	#container{
		flex-direction: column;
	}
}

.nav-active {
	transform: translateX(0%) !important;
}

@keyframes navLinkFade {
	from {
		opacity: 0;
		transform: translateX(50px);
	}
	to {
		opacity: 1;
		transform: translateX(0px);
	}
}

.toggle .line1 {
	transform: rotate(-15deg) translate(-5px, 6px);
}

.toggle .line2 {
	opacity: 0;
}

.toggle .line3 {
	transform: rotate(15deg) translate(-5px, -6px);
}
