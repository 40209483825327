html {
	min-height: 100%;
}
body {
	padding: 0;
	margin: 0;
}
html,
body {
	height: fit-content;
}

#text {
	padding-left: 10%;
	padding-right: 10%;
	padding: 20px;
	margin-top: 20px;
	font-family: "Times New Roman", Times, serif;
	position: relative;
	display: inline;
}

#workPlaces {
	font-size: 60px;
	text-decoration: underline;
	width: 100%;
	font-family: 'Bebas Neue', cursive;
	letter-spacing: 3px;
	padding-left: 20px;
}

#text h2 {
	font-size: 2.5vh;
}

#corner1 {
	position: relative;
	border-radius: 25px;
	background: black;

	width: 15px;
	min-height: 100%;
	box-sizing: border-box;
	float: left;
	z-index: -1;
}
/* .circle {
	position: fixed;
	-webkit-backface-visibility: hidden;
	height: 50px;
	width: 50px;
	background-color: black;
	border-radius: 50%;
	display: block;
	bottom: 0;
	left: calc(20vw - 7.5px);
	top: 97.5%;
} */
#box {
	position: relative;
	border-style: dotted;
	width: 100%;
	z-index: 0;
	min-height: 50vh;

	/* padding: 5px; */
	/* top: 5vh; */
	background-color: black;
}

.intresserad {
	position: absolute;
	float: right;
	border: 3px;
	border-style: solid;
	border-color: black;
	bottom: 0px;
	padding-left: 5px;
	right: 0px;
	opacity: 0.3;
	padding-right: 5px;
}
.intresserad:hover {
	opacity: 1;
}
.intresserad p {
	font-size: 15px;
	font-style: oblique;
}

#box iframe {
	position: absolute;
	display: inline-block;
	width: 100%;
	height: 100%;
}
#middleCont {
	position: relative;
	display: flex;
	flex-direction: column;
	width:100%;
}
#textField {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	margin-top: 20vh;
}
#textField img {
	height: 20vh;
	width: 20vh;
	display: inline-block;
	margin-right: 100%;
}
#textField h1 {
	display: inline-block;
	font-size: 4vh;
}
#textField p {
	font-size: 2vh;
	clear: both;
}

#textField ul {
    height:auto;
}
.workexperience {
	position: relative;
	margin: 2vw;
	margin-top: 0;
	width: 50%;
	margin-bottom: 5vh;
}

.workexperience h1{
    padding-bottom:10px;
}

section {
	position: relative;
	display: flex;
	flex-direction: column;
}
#section2 {
	display: flex;
}

#BigFirstLetter::first-letter {
	float: left;
	line-height: 50%;
	width: .7em;
	font-size: 400%;
	font-family: georgia;
}

@media screen and (max-width: 768px) {
	.workexperience {
		left: 0;
	}
}
@media screen and (max-width: 500px) {
	#workPlaces {
		font-size: 8vw;
	}

	#Kombinationsbehandlingar {
		font-size: 7vw;
	}
	.intresserad {
		display: none;
	}
}
@media only screen and (min-device-width: 375px) {
}
